import { Alert, Button, Dialog, FormGroup, Icon } from '@blueprintjs/core'
import _ from 'lodash'
import React, { useState } from 'react'
import uuidv4 from 'uuid/v4'

import DropdownSelector from './DropdownSelector.jsx'
import SpecificCancerQuestions from './SpecificCancerQuestions.jsx'
import * as CancerTypes from '../utils/CancerTypes.js'
import { PersonFieldPaths as paths } from '../utils/QuestionnaireStateManager.js'

export default function CancerHistory({ label, personId, disabled, stateManager }) {
  const [modalOpened, setModalOpened] = useState(false)
  const [modalConfirmationOpened, setModalConfirmationOpened] = useState(false)
  const [indexDelete, setIndexDelete] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState({})
  const [selectedOption, setSelectedOption] = useState({})
  const [qualifiers, setQualifiers] = useState([])
  const [secondBreastCancer, setSecondBreastCancer] = useState()
  const cancers = stateManager.getPersonValue(personId, paths.CANCERS) || []
  const deleteCancerValue = (index) => {
    stateManager.removeFromArrayInPath(personId, paths.CANCERS, index)
  }

  const addDiagnosis = () => {
    const cancerIndex = cancers.findIndex(({ id }) => id === selectedOption.id)
    const filteredUndefined = qualifiers.filter((item) => item)
    // A little complicated because of `Breast Caner`
    // 1) if secondBreastCancer is open and then no qualifiers are selected
    const newQualifier =
      secondBreastCancer === 'Y' && !filteredUndefined.length
        ? [{ id: uuidv4() }, { id: uuidv4() }]
        : // 2) if secondBreastCancer is open and then a single qualifier is selected
        secondBreastCancer === 'Y' && filteredUndefined.length === 1
        ? [...filteredUndefined, { id: uuidv4() }]
        : // 3-1) if secondBreastCancer is open and then two all qualifiers are selected
        (secondBreastCancer === 'Y' && filteredUndefined.length === 2) ||
          // 3-2) or other cancer category is selected and its own qualifier is also selected
          (secondBreastCancer !== 'Y' && filteredUndefined.length)
        ? [...filteredUndefined]
        : [{ id: uuidv4() }]

    // Adding qualifiers when the cancer HPO ID already exists.
    if (cancerIndex > -1) {
      const addQualifier = {
        ...cancers[cancerIndex],
        qualifiers: [...cancers[cancerIndex].qualifiers, ...newQualifier],
      }

      const _cancers = [...cancers]

      _cancers[cancerIndex] = addQualifier
      stateManager.setPersonValue(personId, paths.CANCERS, [..._cancers])
    } else {
      stateManager.addToPersonArray(personId, paths.CANCERS, {
        id: selectedOption.id,
        label: selectedOption.label,
        isPresent: 'Y',
        qualifiers: [...newQualifier],
      })
    }

    setModalOpened(false)
    setSecondBreastCancer(undefined)
    resetValues()
  }

  const deleteQualifier = (cancerId, qualifierId) => {
    const cancerIndex = cancers.findIndex(({ id }) => id === cancerId)
    const filteredQualifiers = cancers[cancerIndex].qualifiers.filter(
      ({ id }) => id !== qualifierId,
    )

    const _cancers = [...cancers]

    if (!filteredQualifiers.length) {
      _cancers.splice(cancerIndex, 1)
    } else {
      const deleteQualifier = {
        ...cancers[cancerIndex],
        qualifiers: [...filteredQualifiers],
      }

      _cancers[cancerIndex] = deleteQualifier
    }

    stateManager.setPersonValue(personId, paths.CANCERS, [..._cancers])
  }

  const confirmDeletion = () => {
    return (
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
        intent="danger"
        isOpen={modalConfirmationOpened}
        onCancel={() => {
          setModalConfirmationOpened(false)
          setIndexDelete(null)
        }}
        onConfirm={() => {
          deleteCancerValue(indexDelete)
          setModalConfirmationOpened(false)
        }}
      >
        <p>Are you sure you want to remove?</p>
      </Alert>
    )
  }

  const renderDiagnosisAdded = () => {
    return cancers.map(({ id: cancerId, qualifiers }, cancerIndex) => {
      const newQualifiers = []

      // need to deeply clone to separate cancer's qualifiers from this rendering.
      qualifiers.forEach(({ ...arg }) => {
        newQualifiers.push({ ...arg })
      })

      return (
        <div className="person-entry" key={cancerIndex}>
          <div className="cardTitle cardTitle-white cardTitle-left">
            <div className="d-flex align-items-center">
              <div className="person-entry-info">
                <h4>{CancerTypes.findCancerLabel(cancerId)}</h4>
                {newQualifiers
                  .sort((a, b) => {
                    // sorting number and grouping undefined and number items
                    // The numbered age is the higher priority.
                    // Also, need to specify `undefined` because the age 0 can be available.
                    if (
                      a.numericAgeAtDiagnosis === undefined ||
                      b.numericAgeAtDiagnosis === undefined
                    ) {
                      if (a.numericAgeAtDiagnosis === undefined) {
                        a.numericAgeAtDiagnosis = Number.MAX_VALUE
                      }
                      if (b.numericAgeAtDiagnosis === undefined) {
                        b.numericAgeAtDiagnosis = Number.MAX_VALUE
                      }
                    }

                    return a.numericAgeAtDiagnosis - b.numericAgeAtDiagnosis
                  })
                  .map(({ numericAgeAtDiagnosis, laterality, id: qualifierId }, qualifierIndex) => {
                    return (
                      <p className="subLabel noUppercase" key={qualifierIndex}>
                        -{' '}
                        <span>
                          Diagnosed at{' '}
                          {numericAgeAtDiagnosis > Number.MAX_VALUE || !numericAgeAtDiagnosis
                            ? 'unknown age'
                            : numericAgeAtDiagnosis}
                        </span>
                        {laterality && <span>{` (${laterality})`}</span>}
                        <button
                          className="cancerDeleteButton"
                          onClick={() => deleteQualifier(cancerId, qualifierId)}
                        >
                          <Icon className="smallCrossIcon" icon="cross" size={10} intent="danger" />
                        </button>
                      </p>
                    )
                  })}
              </div>
            </div>
            <div className="d-flex align-items-center">
              <Button
                rightIcon="trash"
                intent="danger"
                minimal={true}
                onClick={() => {
                  setIndexDelete(cancerIndex)
                  setModalConfirmationOpened(true)
                }}
              />
            </div>
          </div>
        </div>
      )
    })
  }

  const resetValues = () => {
    setSelectedCategory({})
    setSelectedOption({})
    setQualifiers([])
  }

  const filterCancerCategories = () => {
    const sex = stateManager.getPersonValue(personId, paths.SEX)

    return CancerTypes.CANCER_CATEGORIES.filter((category) => {
      if (!category.genderRestriction) {
        return category
      } else {
        return category.genderRestriction === sex
      }
    })
  }

  // It is managed by the `index` argument.
  // In this case, the previous filter
  // function should not be implemented here
  // because the number of elements varies in terms of qualifier selection.
  const handleQualifiersChange = (index, attributeValue) => {
    if (!qualifiers[index]) {
      qualifiers[index] = { ...attributeValue, id: uuidv4() }
    } else {
      qualifiers[index] = { ...qualifiers[index], ...attributeValue }
    }

    setQualifiers([...qualifiers])
  }

  return (
    <FormGroup label={label}>
      {renderDiagnosisAdded()}
      {confirmDeletion()}
      <Button
        icon="add"
        text="Add Diagnosis"
        outlined={true}
        intent="primary"
        className="addButton"
        fill={true}
        disabled={disabled}
        onClick={() => setModalOpened(true)}
      />
      <Dialog isOpen={modalOpened} onClose={() => setModalOpened(false)} title="Add Diagnosis">
        <div className="specificCancerQuestions">
          <FormGroup label="Category">
            <DropdownSelector
              defaultLabel="Select type"
              items={filterCancerCategories()}
              onItemSelect={(e) => {
                setSelectedOption(e)
                setSelectedCategory(e)
                setQualifiers(
                  qualifiers.map((item) => {
                    return _.omit(item, ['laterality'])
                  }),
                )
              }}
              noResultsOption={{
                name: 'Other',
                label: 'Other',
                id: null,
              }}
            ></DropdownSelector>
          </FormGroup>
          <SpecificCancerQuestions
            option={selectedCategory}
            onSelectedType={setSelectedOption}
            selectedOption={selectedOption}
            onQualifiers={handleQualifiersChange}
            isProband={stateManager.getProband().id === personId}
            secondBreastCancer={secondBreastCancer}
            setSecondBreastCancer={setSecondBreastCancer}
            qualifiers={qualifiers}
            setQualifiers={setQualifiers}
          />
          <div className="cancerDialogButtons">
            <Button
              intent="primary"
              outlined={true}
              large="true"
              text="Cancel"
              onClick={() => {
                setModalOpened(false)
                resetValues()
              }}
            />
            <Button
              type="submit"
              large="true"
              text="Add"
              intent="primary"
              rightIcon={'add'}
              onClick={() => addDiagnosis()}
              disabled={_.isEmpty(selectedOption) || selectedOption.label === 'Other'}
            />
          </div>
        </div>
      </Dialog>
    </FormGroup>
  )
}
