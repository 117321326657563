import { FormGroup } from '@blueprintjs/core'
import React, { useContext } from 'react'
import ButtonGroup from './ButtonGroup'
import Context from '../utils/context/Context'
import { getLabel, getParentStrFromSex, capitalize } from '../utils/helpers'
import {
  getFieldName,
  PersonFieldPaths,
  PersonProperties,
} from '../utils/QuestionnaireStateManager.js'

function HalfSiblingParent({ stateManager, referencePersonId, resolvePersonId }) {
  const { localization } = useContext(Context)
  const siblingOfPersonId = resolvePersonId(false, stateManager.cpt.state)

  const onChange = (e) => {
    const sharedParentSex = e

    stateManager.setPersonValue(
      referencePersonId,
      PersonFieldPaths.PROPERTY_VALUE,
      capitalize(getParentStrFromSex(e)),
      {
        propertyIdx: {
          [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: PersonProperties.SHARED_PARENT,
        },
      },
    )

    stateManager.setHalfSiblingSharedParentType(
      referencePersonId,
      siblingOfPersonId,
      sharedParentSex,
    )
  }

  return (
    <FormGroup>
      <ButtonGroup
        onChange={onChange}
        selectedValue={stateManager
          .getFamilyHelper()
          .getHalfSiblingSharedParentType(referencePersonId, siblingOfPersonId)}
        options={[
          ['F', getLabel(localization, PersonFieldPaths.SHARED_PARENT_MOTHER, 'person')],
          ['M', getLabel(localization, PersonFieldPaths.SHARED_PARENT_FATHER, 'person')],
        ]}
      />
    </FormGroup>
  )
}

export default HalfSiblingParent
