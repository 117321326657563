import { NonIdealState } from '@blueprintjs/core'
import { navigate } from '@reach/router'
import React, { useEffect } from 'react'

function Home({ location }) {
  useEffect(() => {
    if (location.key === 'initial') {
      navigate('/auth')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="bp3-non-ideal-state">
      <NonIdealState
        icon="crown"
        title="Welcome to the Pre-Visit Questionnaire!"
        description={
          'To log in,' +
          ' please paste the link provided to you by your service provider in the url bar.'
        }
      />
    </div>
  )
}

export default Home
