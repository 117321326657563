import { Alert, Intent } from '@blueprintjs/core'
import _ from 'lodash'
import React, { useState } from 'react'
import InternalCard from './InternalCard'
import { getFullName } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'

const { FIRST_NAME, LAST_NAME } = PersonFieldPaths

const MultiPersonCards = ({ personIds, entryRenderer, personLabel, stateManager, getValue }) => {
  const [state, setState] = useState({
    isRemoveAlertOpen: false,
    personIdToRemove: null,
  })

  const removePersonEntry = (personId) => {
    setState({
      personIdToRemove: personId,
      isRemoveAlertOpen: true,
    })
  }

  const confirmRemovePersonEntry = () => {
    stateManager.removePerson(state.personIdToRemove)
    setState({
      isRemoveAlertOpen: false,
      personIdToRemove: null,
    })
  }

  const getMedicalConditions = (cousinId) => {
    const lifeStatus = stateManager.getPersonValue(cousinId, PersonFieldPaths.ALIVE_STATUS)
    const heart = stateManager.getPersonValue(cousinId, PersonFieldPaths.CONDITION_IS_PRESENT, {
      conditionName: 'heart',
    })

    const medicalConditions = [
      {
        status: heart === 'Y',
        id: 'heart',
        description: 'Person has Heart Condition',
      },
      {
        status: lifeStatus === 'N',
        id: 'deceased',
        description: 'Person is deceased',
      },
    ]

    return medicalConditions
  }

  return (
    <React.Fragment>
      {personIds.map((personId) => {
        const {
          name = {},
          lifeStatus = {},
          dateOfBirth = '',
          properties = [],
        } = stateManager.getPersonById(personId) || {}

        const _properties = properties.find(
          ({ type }) => type === 'sharedParent' || type === 'twin',
        )

        const filled =
          !!Object.keys(name).length ||
          !!Object.keys(lifeStatus).length ||
          !!dateOfBirth ||
          !!_properties

        return (
          <InternalCard
            key={personId}
            opened={!filled}
            fullName={getFullName(getValue(personId, FIRST_NAME), getValue(personId, LAST_NAME))}
            relationship={personLabel(personId)}
            removePersonEntry={_.partial(removePersonEntry, personId)}
            medicalConditions={getMedicalConditions(personId)}
          >
            {entryRenderer(personId)}
          </InternalCard>
        )
      })}
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
        intent={Intent.DANGER}
        isOpen={state.isRemoveAlertOpen}
        onCancel={() =>
          setState({
            isRemoveAlertOpen: false,
            personIdToRemove: null,
          })
        }
        onConfirm={confirmRemovePersonEntry}
      >
        <p>Are you sure you want to remove?</p>
      </Alert>
    </React.Fragment>
  )
}

export default MultiPersonCards
