import { FormGroup } from '@blueprintjs/core'
import React, { useState, useEffect, useRef } from 'react'
import DropdownSelector from './DropdownSelector.jsx'
import * as CancerTypes from '../utils/CancerTypes.js'
import ConditionalSection from '../widgets/ConditionalSection'
import IntegerInput from '../widgets/IntegerInput'

export default function SpecificCancerQuestions({
  option,
  onSelectedType,
  onQualifiers,
  secondBreastCancer,
  setSecondBreastCancer,
  selectedOption,
  qualifiers,
  setQualifiers,
  isProband,
}) {
  const [ageDiagnosis1, setAgeDiagnosis1] = useState()
  const [ageDiagnosis2, setAgeDiagnosis2] = useState()
  const [selectedType, setSelectedType] = useState()
  const integerInputRef = useRef(null)
  const selectRef = useRef(null)

  useEffect(() => {
    integerInputRef.current.deleteInput()
    // Should only work for `Breast` or `Breast Cancer`.
    selectRef.current?.deleteSelectedValue()
    setAgeDiagnosis1('')
    setAgeDiagnosis2('')
    setQualifiers([])
    setSecondBreastCancer()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption.id])

  const getCancerTypes = () => {
    switch (option.label) {
      case 'Breast':
        return CancerTypes.BREAST_TYPES
      case 'Ovarian':
        return CancerTypes.OVARIAN_TYPES
      case 'Skin':
        return CancerTypes.SKIN_TYPES
      case 'Kidney and urinary tract':
        return CancerTypes.KIDNEY_AND_URINARY_TYPES
      case 'Brain':
        return CancerTypes.BRAIN_TYPES
      case 'Colon':
        return CancerTypes.COLON_TYPES
      case 'Other':
        return CancerTypes.OTHER_TYPES
      default:
        return null
    }
  }

  const renderCancerTypes = () => {
    if (getCancerTypes(option.label)) {
      return (
        <FormGroup label="What type?">
          <DropdownSelector
            defaultLabel="Select type"
            items={getCancerTypes(option.label)}
            onItemSelect={(e) => {
              onSelectedType(e)
              setSelectedType(e)
            }}
            key={option.id}
          ></DropdownSelector>
        </FormGroup>
      )
    }
  }

  const renderBreastSideSelect = () => {
    if (option.label === 'Breast') {
      return (
        <React.Fragment>
          <FormGroup label="Which side of the body?">
            <DropdownSelector
              defaultLabel="Select side"
              items={CancerTypes.BREAST_LATERALITY}
              onItemSelect={(e) => onQualifiers(0, { laterality: e.id })}
              ref={selectRef}
            ></DropdownSelector>
          </FormGroup>
          {renderSecondOccurrence()}
        </React.Fragment>
      )
    }
  }

  const renderSecondOccurrence = () => {
    if (selectedType && selectedType.label === 'Breast cancer') {
      return (
        <ConditionalSection
          label={
            isProband
              ? 'Have you had breast cancer more than once?'
              : 'Has this person had breast cancer more than once?'
          }
          showWhenCondition="Y"
          conditionState={secondBreastCancer}
          onConditionStateChange={(e) => {
            setSecondBreastCancer(e)
          }}
          orderSchema={['Y', 'N', 'U']}
          cleanUpChildQuestions={() => {
            setAgeDiagnosis2(undefined)

            if (qualifiers.length === 2) {
              if (qualifiers[0]) {
                setQualifiers([qualifiers[0]])
              } else {
                setQualifiers([])
              }
            }
          }}
        >
          <FormGroup label="Which side of the body?">
            <DropdownSelector
              defaultLabel="Select side"
              items={CancerTypes.BREAST_LATERALITY}
              onItemSelect={(e) => onQualifiers(1, { laterality: e.id })}
            ></DropdownSelector>
          </FormGroup>
          <FormGroup label="Age at second diagnosis?">
            <IntegerInput
              onValueChange={(e) => {
                onQualifiers(1, {
                  numericAgeAtDiagnosis: e,
                })
                setAgeDiagnosis2(e)
              }}
              value={ageDiagnosis2}
              minorStepSize={1}
              min={0}
              max={120}
            />
          </FormGroup>
        </ConditionalSection>
      )
    }
  }

  return (
    <div>
      {renderCancerTypes()}
      <FormGroup label="Age at diagnosis?">
        <IntegerInput
          onValueChange={(e) => {
            onQualifiers(0, {
              numericAgeAtDiagnosis: e,
            })
            setAgeDiagnosis1(e)
          }}
          value={ageDiagnosis1}
          ref={integerInputRef}
          minorStepSize={1}
          min={0}
          max={120}
        />
      </FormGroup>
      {renderBreastSideSelect()}
    </div>
  )
}
