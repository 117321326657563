import React, { useContext } from 'react'
import ConditionalSection from './ConditionalSection'
import DeathDetails from './DeathDetails'
import Context from '../utils/context/Context'
import { getLabel } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'

export default function LifeStatus({ personId, stateManager, personIdWithCreate }) {
  const { ALIVE_STATUS } = PersonFieldPaths
  const { localization } = useContext(Context)
  const parsedLifeStatus = ALIVE_STATUS.split('.')
  const person = stateManager.getState().persons[personId]

  return (
    <ConditionalSection
      label={getLabel(localization, ALIVE_STATUS)}
      showWhenCondition="N"
      conditionState={stateManager.getPersonValue(personId, ALIVE_STATUS)}
      onConditionStateChange={(e) => {
        stateManager.setPersonValue(personIdWithCreate, ALIVE_STATUS, e)

        if (
          (person?.lifeStatus?.causeOfDeath || person?.lifeStatus?.ageOfDeath) &&
          (e === 'Y' || e === 'U')
        ) {
          stateManager.updatePersonValue({
            personId,
            path: [parsedLifeStatus[0]],
            newFieldValue: { [parsedLifeStatus[1]]: e },
          })
        }
      }}
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => null}
    >
      <DeathDetails
        stateManager={stateManager}
        personId={personId}
        personIdWithCreate={personIdWithCreate}
      />
    </ConditionalSection>
  )
}
