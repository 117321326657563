import { NonIdealState } from '@blueprintjs/core'
import { navigate } from '@reach/router'
import React, { useContext, useEffect } from 'react'
import Context from './utils/context/Context'
import { saveMetricToBackend } from './utils/persistance'

export default function ThankYou({ location }) {
  const {
    thankYou: { instructions, url, urlLabel },
    appState,
  } = useContext(Context)

  useEffect(() => {
    if (location.key === 'initial') {
      navigate('/auth')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Invoke `saveMetricBackend` to send the metric data.
   * @returns {Promise<undefined>}
   *
   * Todo: Still need to wait until
   * the backend resolves `Unauthorized` and `Session` issues.
   */
  const handleMetrics = async (event) => {
    event.preventDefault()

    // We do not need to update the `appState` in `Thank you` page
    // because the user can not get back to our app.
    return saveMetricToBackend(
      {
        ...appState.metrics,
        sourcePage: 'Thank you',
        destinationPage: 'Feedback',
        eventType: 'feedbackLink',
      },
      // It should be `true` because we need to send the session info
      // when the user clicks on the url in the Thank you page.
      true,
      () => {
        window.location.ref = url
      },
    )
  }

  const description = (
    <>
      <p>
        Thank you for taking the time to fill out this questionnaire. Your Pre-visit questionnaire
        has been submitted.
      </p>
      <p>{instructions}</p>
      {urlLabel && url ? <a onClick={handleMetrics}>{urlLabel}</a> : null}
    </>
  )

  return (
    <div>
      <NonIdealState
        icon="saved"
        title="Thank you for submitting!"
        description={description}
        className="thankYou"
      />
    </div>
  )
}
