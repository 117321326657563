import { Callout, NonIdealState } from '@blueprintjs/core'
import { navigate } from '@reach/router'
import React, { useContext, useEffect } from 'react'
import Context from './utils/context/Context'

// Please, use a separate Page for the error notification.
// Each page has a unique role for the app.
function Message({ location }) {
  const { fatalErrorMessage, setLoggingIn, loggingIn } = useContext(Context)

  useEffect(() => {
    if (!loggingIn) {
      setLoggingIn(() => true)
    }

    if (location.key === 'initial') {
      navigate('/auth')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!fatalErrorMessage && !location.state?.err) {
    return null
  }

  return (
    <div>
      <div className="bp3-non-ideal-state">
        <NonIdealState
          icon="error"
          title="Sorry, unable to Load Questionnaire"
          description={
            'Please try again in a few minutes' +
            ' or reach out to your service provider if the problem continues.'
          }
        />
        <Callout className="home-callout" intent="danger" title="Error Message" icon={false}>
          {fatalErrorMessage || null}
          {location.state?.err || null}
        </Callout>
      </div>
    </div>
  )
}

export default Message
