import { navigate } from '@reach/router'
import { useEffect } from 'react'
import { login, getAccessToken } from '../utils/persistance'

const AuthHooks = ({
  token,
  setAuthenticated,
  setLoggingIn,
  setToken,
  setFatalErrorMessage,
  getErrorMessage,
}) => {
  // The login call must not be in App.js file
  // because it should not be called again after login id done
  // unless the idle timer is invoked.
  useEffect(() => {
    login(
      async () => {
        await navigate('/questionnaire', { state: { fromRedirect: true }, replace: true })
      },
      (err) => {
        setAuthenticated(() => false)
        setLoggingIn(() => true)

        if (getAccessToken() !== '') {
          setFatalErrorMessage(getErrorMessage(err))
        }
      },
      // Please do not mix up setAuthenticated and navigate calls.
      // They should be synchronized functions in sequence.
      () => {
        setToken('')
        setAuthenticated(() => true)
        setLoggingIn(() => false)
      },
      token,
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return true
}

export default AuthHooks
