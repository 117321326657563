import { Tree, Hotkey, Hotkeys, HotkeysTarget, Icon, Tooltip, Button } from '@blueprintjs/core'
import { navigate } from '@reach/router'
import classNames from 'classnames'
import _ from 'lodash'
import React, { Component } from 'react'

import CurrentStep from './CurrentStep'
import Context from './utils/context/Context'
import { getLabel } from './utils/helpers'
import saveToBackend, { finishSave, submitData, saveMetricToBackend } from './utils/persistance'
import { NavigationPaths, EventType } from './utils/QuestionnaireStateManager.js'
import PopUpCard from './widgets/PopUpCard'

class Navigation extends Component {
  static contextType = Context
  constructor(props) {
    super(props)

    this.scrollBody = React.createRef()

    this.state = {
      selectedNavId: null,
      expandedNodeId: null,
      burgerMenuOpened: false,
      forceUpdate: false,
    }
  }

  render() {
    const children = this.props.children.filter(Boolean)

    return (
      <React.Fragment>
        <div className={this.state.burgerMenuOpened ? 'burgerMenu opened' : 'burgerMenu'}>
          <Button
            rightIcon={this.state.burgerMenuOpened ? 'cross' : 'menu'}
            intent="primary"
            minimal={true}
            large={true}
            onClick={() => this.setState({ burgerMenuOpened: !this.state.burgerMenuOpened })}
          ></Button>
        </div>
        <div
          className={classNames('questionnaire-nav-sidebar', { open: this.state.burgerMenuOpened })}
        >
          <Tree
            key="sidebar"
            contents={this.getStepsAndFolders().map((step, i) =>
              this.renderSidebarNavItem(step, i),
            )}
            onNodeClick={(node) => {
              node.eventType = EventType.PAGE_JUMP
              this.saveAndGoToStep(node)
            }}
            onNodeExpand={(node) => this.saveAndGoToStep(node)}
            onNodeCollapse={(node) => this.saveAndGoToStep(node)}
          />
          {children.filter((step) => step.key === 'controls')}
        </div>
        <CurrentStep
          ref={this.scrollBody}
          cptState={this.props.cptState}
          setState={this.props.setState}
          addToast={this.props.addToast}
          icon={_.get(this.getCurStep(), 'props.icon')}
          navTitle={_.get(this.getCurStep(), 'props.navTitle')}
          currentStep={this.getCurStep()}
          loading={this.context.loading}
          handleMovePrevious={this.handleMovePrevious}
          canMovePrevious={this.canMovePrevious}
          isLastStep={this.isLastStep}
          saveAndMoveToNext={this.saveAndMoveToNext}
          setSubmitWarning={() => this.context.setDialogBox({ show: true, type: 'submitWarning' })}
          btnLabels={[
            getLabel(this.context.localization, NavigationPaths.BACK, 'navigation'),
            getLabel(this.context.localization, NavigationPaths.NEXT, 'navigation'),
          ]}
        />
        {this.context.dialogBox.show && this.context.dialogBox.type === 'submitWarning' && (
          <PopUpCard
            title={'Are you ready to submit the questionnaire?'}
            intent={'warning'}
            message={
              'Your responses will be final and you will not be able to undo this action.' +
              'You will be able to discuss or edit information' +
              ' with your genetics provider at your appointment.'
            }
            buttons={[
              {
                id: 'cancel',
                text: 'Cancel',
                intent: 'none',
                action: () => this.context.setDialogBox({ show: false }),
              },
              {
                id: 'submit',
                text: 'Submit',
                intent: 'primary',
                action: this.submitQuestionnaire,
                loading: this.context.loading.submit,
              },
            ]}
          />
        )}

        {this.context.dialogBox.show && this.context.dialogBox.type === 'showSaveError' && (
          <PopUpCard
            title={'Save Error'}
            intent={'warning'}
            message={
              'Please check your internet connection or' +
              ' try again later to ensure that your responses are captured correctly.'
            }
            errorMessage={this.context.dialogBox.errorMessage}
            buttons={[
              {
                id: 'proceedWithoutSaving',
                text: 'Proceed without Saving',
                intent: 'none',
                action: () => this.nextAction(this.proceedWithoutSaving),
              },
              {
                id: 'trySaveAgain',
                text: 'Try Again',
                intent: 'none',
                action: this.saveAndMoveToNext,
              },
            ]}
          />
        )}

        {this.context.dialogBox.show && this.context.dialogBox.type === 'submitFailed' && (
          <PopUpCard
            title={'Submission Error'}
            intent={'danger'}
            message={
              'Please check your internet connection' +
              ' or try again later to ensure that your responses are captured correctly.'
            }
            errorMessage={this.context.dialogBox.errorMessage}
            buttons={[
              {
                id: 'cancel',
                text: 'Cancel',
                intent: 'none',
                action: () => this.context.setDialogBox({ show: false }),
              },
              {
                id: 'trySaveAgain',
                text: 'Try Again',
                intent: 'danger',
                action: this.submitQuestionnaire,
              },
            ]}
          />
        )}

        {this.context.dialogBox.show && this.context.dialogBox.type === 'missingFields' && (
          <PopUpCard
            title={'Missing required fields'}
            intent={'danger'}
            message={this.context.dialogBox.errorMessage}
            buttons={[
              {
                id: 'goBack',
                text: 'Go Back',
                intent: 'primary',
                action: () => {
                  this.saveAndGoToStep({
                    id: 'person-proband',
                    label: 'Your Information',
                    eventType: EventType.GO_BACK,
                  })

                  this.context.setDialogBox({ show: false })
                },
              },
            ]}
          />
        )}
      </React.Fragment>
    )
  }

  componentDidUpdate(prevProps = undefined, prevState) {
    if (prevState.forceUpdate !== this.state.forceUpdate) {
      this.updateMetricWithBackButton()
    }
  }

  scrollTop = () => {
    this.scrollBody.current.scrollTop = 0
  }

  canMovePrevious = () => {
    return this.getCurStepIndex(this.getCurStep()) > 0
  }

  isLastStep = () => {
    return !(this.getCurStepIndex(this.getCurStep()) < this.getSteps().length - 1)
  }

  handleMovePrevious = () => {
    this.setState({ ...this.state, forceUpdate: !this.state.forceUpdate })
    this.scrollTop()

    return this.moveSteps(-1, this.getCurStepIndex(this.getCurStep()), EventType.PREVIOUS_STEP)
  }

  handleMoveNext = (curStepId) => {
    const stepIndex = curStepId
      ? this.getCurStepIndex(this.getCurStep(curStepId))
      : this.getCurStepIndex(this.getCurStep())

    this.scrollTop()

    return this.moveSteps(1, stepIndex, EventType.SAVE_AND_CONTINUE)
  }

  // TO DO - merge saveAndMoveToNext and saveAndGoToStep to avoid repetition
  saveAndMoveToNext = () => {
    saveToBackend(
      this.props.cptState,
      this.props.setState,
      this.context.setLoading,
      () => {},
      finishSave,
      this.context.loading,
      'save',
    )
      .then((success) => {
        this.handleMoveNext()
        this.scrollTop()
      })
      .catch((err) => {
        this.context.setDialogBox({
          type: 'showSaveError',
          node: null,
          action: this.handleMoveNext,
          show: true,
          errorMessage: err,
        })
        this.scrollTop()
      })
  }

  saveAndGoToStep = (node) => {
    if (node.type === 'folder') {
      if (node.id === this.state.expandedNodeId) {
        this.setState({
          expandedNodeId: false,
        })
      } else {
        this.setState({
          expandedNodeId: node.id,
        })
      }
    } else {
      saveToBackend(
        this.props.cptState,
        this.props.setState,
        this.context.setLoading,
        () => {},
        finishSave,
        this.context.loading,
        'save',
      )
        .then((success) => {
          this.setCurStep(node)
        })
        .catch((err) => {
          this.context.setDialogBox({
            type: 'showSaveError',
            node,
            action: this.setCurStep,
            show: true,
            errorMessage: err,
          })
        })
    }
  }

  nextAction = () => {
    this.context.dialogBox.action(this.context.dialogBox.node)
    this.context.setDialogBox({ show: false })
    this.scrollTop()
  }

  // check if all required fields are completed
  missingRequiredFields() {
    const p = this.props.proband
    const missingFields = []

    // Full name
    if (_.isEmpty(p.name?.firstName)) missingFields.push('First Name')
    if (_.isEmpty(p.name?.lastName)) missingFields.push('Last Name')
    // Sex field
    if (_.isEmpty(p.sex) || p.sex === 'U') missingFields.push('Sex assigned at birth')

    return _.isEmpty(missingFields) ? null : missingFields
  }

  submitQuestionnaire = () => {
    const fields = this.missingRequiredFields()

    if (fields) {
      this.context.setDialogBox({
        show: true,
        type: 'missingFields',
        errorMessage: 'You must fill out all required fields: ' + fields.join(', '),
      })

      return
    }

    saveToBackend(
      this.props.cptState,
      this.props.setState,
      this.context.setLoading,
      () => {},
      submitData,
      this.context.loading,
      'submit',
      undefined,
      undefined,
      true,
    )
      .then(() => {
        this.context.setDialogBox({ show: false })
        navigate('/thankyou', { replace: true })
      })
      .catch((err) => {
        this.context.setDialogBox({ show: true, type: 'submitFailed', errorMessage: err })
      })
  }

  removePersonFromSidebar = (e, removePerson, curStepId) => {
    e.stopPropagation()
    removePerson()
    this.handleMoveNext(curStepId)
  }

  renderSidebarNavItem = (step) => {
    if (Array.isArray(step)) {
      const childNodes = step.map((childNode, i) => {
        if (i !== 0) {
          return {
            key: childNode.key,
            id: childNode.props.navId,
            label: childNode.props.navTitle,
            disabled: this.context.loading.save,
            isSelected: childNode === this.getCurStep(),
            secondaryLabel: childNode.props.removePerson && (
              <Tooltip content="Remove Person">
                <Icon
                  className="sidebar-removePerson"
                  icon="cross"
                  iconSize="10px"
                  intent="danger"
                  onClick={(e) =>
                    this.removePersonFromSidebar(
                      e,
                      childNode.props.removePerson,
                      childNode.props.navId,
                    )
                  }
                />
              </Tooltip>
            ),
          }
        } else {
          return childNode
        }
      })

      const folderInfo = {
        key: childNodes[0].id,
        id: childNodes[0].id,
        label: childNodes[0].label,
        disabled: this.context.loading.save,
        isSelected: false,
        isExpanded: childNodes[0].id === this.getExpandedFolder(),
        type: 'folder',
      }
      // set the first item in the array as the folder step

      childNodes.forEach((childNode) => {
        childNode.containingFolder = childNodes[0].id
      })

      // remove folder step from child node list
      childNodes.shift()
      folderInfo.childNodes = childNodes

      return folderInfo
    }

    return {
      key: step.key,
      id: step.props.navId,
      label: step.props.navTitle,
      disabled: this.context.loading.save,
      isSelected: step === this.getCurStep(),
    }
  }

  getExpandedFolder = () => {
    if (this.state.expandedNodeId) {
      const steps = _.flattenDeep(this.getStepsAndFolders()).filter((step) => {
        return step.type === 'folder' && step.id === this.state.expandedNodeId
      })

      return steps[0].id
    } else {
      return false
    }
  }

  getStepsAndFolders = () => {
    return _.flatten(this.props.children).filter((step) => step && step.key !== 'controls')
  }

  getSteps = () => {
    return _.flattenDeep(this.props.children).filter(
      (step) => step && step.type !== 'folder' && step.key !== 'controls',
    )
  }

  /**
   * Uses this.state.selectedNavId to return the currently selected step component instance
   * from this.props.children.
   *
   * this.state.selectedNavId is expected to hold a string corresponding
   * to the navId value of the instance.
   */
  getCurStep = (selectedNavId = this.state.selectedNavId) => {
    if (selectedNavId) {
      const steps = _.flattenDeep(this.getSteps()).filter((step) => {
        return step.type !== 'folder' && step.props[Navigation.NAV_ID_PROPNAME] === selectedNavId
      })

      return steps[0]
    } else {
      return this.getSteps()[0]
    }
  }

  /**
   * Gets the index of the current step by looking through all steps.
   */
  getCurStepIndex = (curStep) => {
    return _.findIndex(this.getSteps(), curStep)
  }

  /**
   * Moves the current navigation step by the requested number of steps.
   *
   * @param {number} numberSteps a negative
   * or positive integer representing the number of steps to move.
   */
  moveSteps = (numberSteps, currentStepIndex, eventType) => {
    const newStepIndex = currentStepIndex + numberSteps

    if (newStepIndex < 0 || newStepIndex >= this.getSteps().length) {
      throw new Error('New step is out of bounds')
    }
    const folderId = this.getContainingFolder(newStepIndex) || this.state.expandedNodeId

    this.setState({
      selectedNavId: this.getSteps()[newStepIndex].props[Navigation.NAV_ID_PROPNAME],
      expandedNodeId: folderId,
    })

    this.updateMetricPages('', newStepIndex, eventType)
  }

  // `handleMovePrevious` does not have http request of `saveToBackend`.
  // So we need to get this.props.cptState at the second renderings.
  // `true` is required to to get `current-session` because `back` button
  // does not request `saveToBackend` function.
  async updateMetricWithBackButton() {
    await saveMetricToBackend(this.props.cptState.metrics, true)
  }

  async updateMetricPages(pageLabel = '', newStepIndex = undefined, eventType) {
    this.props.stateManager.updateMetrics({
      newValue: pageLabel || this.getSteps()[newStepIndex].props?.navTitle,
      eventType,
    })

    // When the back button clicks, it just updates the state values only
    if (eventType === EventType.PREVIOUS_STEP) {
      return
    }

    await saveMetricToBackend(this.props.cptState.metrics, false)
  }

  /**
   * Sets the current step based on navigation ID of the given node.
   *
   * @param {string} node The node to grab the navId from
   */
  setCurStep = (node) => {
    if (node.type !== 'folder') {
      this.setState({
        selectedNavId: node.id,
        burgerMenuOpened: false,
      })
      this.scrollTop()
    }

    if (node.label && node.eventType) {
      this.updateMetricPages(node.label, undefined, node.eventType)
    }
  }

  getContainingFolder = (stepIndex) => {
    const compareStep = (step, currentStep) => {
      if (Array.isArray(step)) {
        return step.map((step) => compareStep(step, currentStep))
      } else {
        return step === currentStep
      }
    }

    if (this.getStepsAndFolders().indexOf(this.getSteps()[stepIndex]) === -1) {
      let index = null

      const folders = this.getStepsAndFolders()
        .filter((step) => Array.isArray(step))
        .map((step) => step.map((childNode) => childNode))

      folders
        .map((step) => compareStep(step, this.getSteps()[stepIndex]))
        .forEach((isContainingStep, i) => {
          if (isContainingStep.includes(true)) {
            index = i
          }
        })

      return folders[index][0].id
    } else {
      return false
    }
  }

  renderHotkeys = () => {
    return (
      <Hotkeys>
        <Hotkey
          global={true}
          combo="left"
          label="Previous step"
          onKeyDown={this.handleMovePrevious}
          disabled={!this.canMovePrevious()}
        />
        <Hotkey
          global={true}
          combo="right"
          label="Next step"
          onKeyDown={() => this.handleMoveNext()}
          disabled={this.isLastStep()}
        />
      </Hotkeys>
    )
  }
}

Navigation.NAV_ID_PROPNAME = 'navId'

export default HotkeysTarget(Navigation)
