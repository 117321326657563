import { Button } from '@blueprintjs/core'
import React, { useContext } from 'react'
import Context from './utils/context/Context'
import saveToBackend, { finishSave, logout, resetForm, downloadPDF } from './utils/persistance'
import { QuestionnaireStateManager } from './utils/QuestionnaireStateManager.js'

function Controls({
  addToast,
  cptState,
  setState,
  demoMode,
  handleMovePrevious,
  canMovePrevious,
  isLastStep,
  saveAndMoveToNext,
  setSubmitWarning,
  btnLabels,
}) {
  const { loading, setLoading, setDialogBox, setLoggingIn, setAuthenticated } = useContext(Context)
  const saveThen = () => {
    saveToBackend(cptState, setState, setLoading, () => {}, finishSave, loading, 'save').catch(
      (err) => {
        setDialogBox({ show: true, type: 'showSaveError', action: () => {}, errorMessage: err })
      },
    )
  }
  const saveButton = (mobile = false) => (
    <Button
      outlined
      large="true"
      icon={'floppy-disk'}
      intent={'primary'}
      onClick={saveThen}
      disabled={loading.save}
      loading={loading.save}
      text={mobile ? '' : 'Save'}
    />
  )

  return (
    <>
      <nav key="next-prev" id="questionnaire-nav-prev-next">
        <div className="mobile-save-button">{saveButton(true)}</div>
        <div className="save-buttons">
          {saveButton()}
          {demoMode && (
            <Button
              outlined
              large="true"
              intent="danger"
              icon="reset"
              disabled={loading.reset}
              loading={loading.reset}
              onClick={() => {
                const newState = {
                  ...cptState,
                  ...QuestionnaireStateManager.getEmptyState(),
                }
                saveToBackend(
                  newState,
                  setState,
                  setLoading,
                  addToast,
                  resetForm,
                  loading,
                  'reset',
                ).catch((err) => {
                  throw new Error(err)
                })
              }}
              text="Reset"
            />
          )}
          {demoMode &&
            demoButtons.map((buttonInfo) => (
              <Button
                outlined
                large="true"
                key={buttonInfo.id}
                icon={buttonInfo.icon}
                intent={buttonInfo.intent}
                onClick={() => {
                  saveToBackend(
                    cptState,
                    setState,
                    setLoading,
                    addToast,
                    buttonInfo.callback,
                    loading,
                    buttonInfo.id,
                    setLoggingIn,
                    setAuthenticated,
                  ).catch((err) => {
                    throw new Error(err)
                  })
                }}
                minimal
                disabled={loading[buttonInfo.id]}
                loading={loading[buttonInfo.id]}
                text={buttonInfo.text}
              />
            ))}
        </div>
        <div className="nav-buttons">
          <Button
            text={btnLabels[0]}
            className="btn-prev"
            intent="primary"
            outlined
            large="true"
            onClick={handleMovePrevious}
            disabled={!canMovePrevious() || loading.save}
            icon={'arrow-left'}
          />
          {!isLastStep() ? (
            <Button
              text={btnLabels[1]}
              intent="primary"
              large="true"
              className="btn-next"
              onClick={() => {
                saveAndMoveToNext()
              }}
              disabled={isLastStep() || loading.save}
              rightIcon={'arrow-right'}
              loading={loading.save}
            />
          ) : (
            <Button
              text="Submit"
              intent="primary"
              large="true"
              className="btn-next"
              onClick={() => setSubmitWarning(true)}
              disabled={!isLastStep()}
              rightIcon={'arrow-right'}
            />
          )}
        </div>
      </nav>
    </>
  )
}

const demoButtons = [
  {
    icon: 'download',
    intent: 'primary',
    callback: downloadPDF,
    text: 'PDF',
    id: 'generate',
  },
  {
    icon: 'log-out',
    intent: 'primary',
    callback: logout,
    text: 'Logout',
    id: 'logout',
  },
]

export default Controls
