import React, { useContext } from 'react'
import { TypeContext } from '../Questionnaire'

const instructions = [
  {
    title: 'It is okay to leave questions blank',
    description: `If there is something you do not know, it is okay to leave some parts blank.
    If you can get more information from family members we would greatly appreciate it.`,
    tag: 2,
  },
  {
    title: 'Some question may be sensitive',
    description:
      'This survey will ask about family members that have passed away ' +
      'and information related to their deaths. ' +
      'If you do not feel able to answer these questions or prefer ' +
      'to discuss them over the phone, please feel free to skip those questions.',
    tag: 0,
  },
  {
    title: 'Reach out to us with questions',
    description:
      'If you have any questions about the information in this questionnaire, ' +
      'please contact your health care provider.',
    tag: 1,
  },
  {
    title: 'Saving Progress',
    description:
      'Do not worry if you cannot fill out the whole questionnaire in one sitting. ' +
      'You can save your progress at any point and return to it at another time. ' +
      'For example, you may wish to talk to your family to complete some sections ' +
      'of the questionnaire.',
    tag: 1,
  },
]

function InstructionsStep() {
  const isSimple = useContext(TypeContext) === 'simple'

  const renderInstructions = () => {
    return instructions.map(({ title, description, tag }, index) => {
      const liComponent = (
        <li key={index}>
          <h4>{title}</h4>
          <p>{description}</p>
        </li>
      )

      if (isSimple) {
        return (tag === 2 || tag === 0) && liComponent
      } else {
        return (tag === 2 || tag === 1) && liComponent
      }
    })
  }

  return (
    <div className="bodyText">
      <ul>{renderInstructions()}</ul>
    </div>
  )
}

export default InstructionsStep
