import { Button } from '@blueprintjs/core'
import _ from 'lodash'
import React, { Component } from 'react'

import Context from '../utils/context/Context'
import { getLabel } from '../utils/helpers'
import {
  PersonFieldPaths,
  PersonProperties,
  getFieldName,
} from '../utils/QuestionnaireStateManager'
import Chooser from '../widgets/Chooser'
import DOBComponent from '../widgets/DOBComponent'
import FullName from '../widgets/FullName'
import HalfSiblingParent from '../widgets/HalfSiblingParent'
import LifeStatus from '../widgets/LifeStatus'
import MultiPersonCards from '../widgets/MultiPersonCards'

class PersonSiblingsStep extends Component {
  constructor(props) {
    super(props)
    this.resolvePersonIdWithCreate = _.partial(this.props.resolvePersonId, true)

    this.state = {
      siblings: [
        { gender: 'M', siblingType: 'full', min: null },
        { gender: 'M', siblingType: 'half', min: null },
        { gender: 'F', siblingType: 'full', min: null },
        { gender: 'F', siblingType: 'half', min: null },
      ],
      siblingPersonIds: [],
    }

    _.bindAll(this, ['handleCountChange'])
  }

  static contextType = Context
  isProband = this.props.targetPerson === 'proband'

  render() {
    const { siblings } = this.state

    return (
      <div>
        <div className="mainLabel">
          <p>{this.isProband ? 'Please add your siblings' : "Please add this person's siblings"}</p>
        </div>
        <div className="siblingGrid">
          {siblings.map(({ gender, siblingType, min }, index) =>
            this.renderAddSiblingButton(gender, siblingType, min, index),
          )}
        </div>
        <MultiPersonCards
          personIds={this.state.siblingPersonIds}
          entryRenderer={this.renderSiblingEntry.bind(this)}
          personLabel={(personId) => this.getPersonLabel(personId)}
          stateManager={this.props.stateManager}
          getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
        />
      </div>
    )
  }

  renderSiblingEntry = (personId) => {
    const isHalfSibling = this.isHalfSibling(personId)
    const { sex: probandSex } = this.props.stateManager.getProband()

    return (
      <div key={'sibling-' + personId}>
        {isHalfSibling ? (
          <HalfSiblingParent
            stateManager={this.props.stateManager}
            referencePersonId={personId}
            resolvePersonId={this.props.resolvePersonId}
            resolvePersonIdWithCreate={this.resolvePersonIdWithCreate}
          />
        ) : null}
        <FullName
          id={personId}
          getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
          setValue={this.props.stateManager.setPersonValue.bind(this.props.stateManager)}
          personIdWithCreate={personId}
          sex={this.props.stateManager.getPersonValue(personId, PersonFieldPaths.SEX)}
        />
        <LifeStatus
          personId={personId}
          personIdWithCreate={personId}
          stateManager={this.props.stateManager}
        />
        <DOBComponent
          stateManager={this.props.stateManager}
          dobDateStr={this.props.stateManager.getPersonValue(
            personId,
            PersonFieldPaths.DATE_OF_BIRTH,
          )}
          onChange={(date) => {
            // eslint-disable-next-line
            this.props.stateManager.setPersonValue(
              personId,
              PersonFieldPaths.DATE_OF_BIRTH,
              date && date !== 'Invalid Date' ? date.toISOString() : '',
            )
          }}
        />
        {this.isProband && this.doesProbandHaveATwin() && !isHalfSibling
          ? this.renderLinkTwins(personId, probandSex)
          : null}
      </div>
    )
  }

  componentDidMount() {
    const { siblings } = this.state

    const _siblings = siblings.map((sibling) => {
      const { gender, siblingType } = sibling
      const value = this.getSiblingCount(gender, siblingType)

      return { ...sibling, min: value }
    })

    this.setState({
      siblings: [..._siblings],
      siblingPersonIds: this.getSiblingPersonIds(),
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.persons !== prevProps.persons) {
      this.setState({
        siblingPersonIds: this.getSiblingPersonIds(),
      })
    }
  }

  /**
   * Returns the label for person cards
   * @param {String} personId
   * @returns {String}
   */
  getPersonLabel(personId) {
    const targetPerson = this.isProband
      ? "Patient's "
      : _.capitalize(this.props.targetPerson) + `'s `
    const siblingSexStr = {
      M: 'brother',
      F: 'sister',
    }[this.props.stateManager.getPersonValue(personId, PersonFieldPaths.SEX)]

    return targetPerson + (this.isHalfSibling(personId) ? 'half-' : '') + siblingSexStr
  }

  /**
   * Returns if a person is a half sibling
   * @param {String} personId
   * @returns {Boolean}
   */
  isHalfSibling(personId) {
    return this.props.stateManager
      .getPersonValue(personId, PersonFieldPaths.RELATIONSHIP_TO_PROBAND)
      ?.includes('Half')
  }

  /**
   * Returns if proband has checked twin status
   * @returns {Boolean}
   */
  doesProbandHaveATwin = () => {
    const hasTwin = this.props.stateManager.getPersonValue(
      this.props.stateManager.getProband().id,
      PersonFieldPaths.PROPERTY_IS_PRESENT,
      { propertyIdx: { [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: 'twin' } },
    )

    return hasTwin && hasTwin === 'Y'
  }

  /**
   * Get the input value of each sibling type.
   * @param {String} siblingSex - an indicator for the sex, `M` or `F`.
   * @param {String} fullOrHalf - an indicator of a half sibling or a full sibling.
   * @returns {Number} The current or updated input value.
   */
  getSiblingCount = (siblingSex, fullOrHalf) => {
    const {
      stateManager: {
        cpt: { state: cptState },
        getFamilyHelper,
      },
      resolvePersonId,
    } = this.props

    return getFamilyHelper(cptState).getSiblingCount(
      resolvePersonId(false, cptState),
      siblingSex,
      fullOrHalf,
    )
  }

  /**
   * Implement a form to increase or decrease a number of siblings.
   * @param {String} siblingSex - an indicator for the sex, `M` or `F`.
   * @param {String} fullOrHalf - an indicator of a half sibling or a full sibling.
   * @returns {React.ReactElement} Sibling forms with the number inputs.
   */
  renderAddSiblingButton = (siblingSex, fullOrHalf, min, index) => {
    const siblingLabel = { M: 'Brother', F: 'Sister' }[siblingSex]
    const siblingCount = this.getSiblingCount(siblingSex, fullOrHalf)

    const buttonHandler = _.partial(
      this.handleCountChange,
      siblingSex,
      fullOrHalf,
      this.props.targetPerson,
      siblingCount + 1,
    )

    return (
      <Button
        key={'add-' + siblingLabel + '-' + index}
        icon="add"
        text={'Add ' + (fullOrHalf === 'half' ? 'Half-' : '') + siblingLabel}
        minimal={true}
        intent="primary"
        className="addButton"
        onClick={buttonHandler}
      />
    )
  }

  /**
   * Implements the count changes
   * @param {String} siblingSex - an indicator for the sex, `M` or `F`.
   * @param {String} fullOrHalf - an indicator of a half sibling or a full sibling.
   * @param {String} targetPerson - an indicator a person to be surveyed.
   * @param {Number} newCount - The new count number in the input.
   */
  handleCountChange = (siblingSex, fullOrHalf, targetPerson, newCount) => {
    const targetPersonArray = ['proband']

    if (['mother', 'father'].includes(targetPerson)) {
      targetPersonArray.push(targetPerson)
    }

    this.props.stateManager.setSiblingCount(
      this.resolvePersonIdWithCreate,
      siblingSex,
      fullOrHalf,
      newCount,
      targetPersonArray,
    )
  }

  getSiblingPersonIds = () => {
    const {
      stateManager: {
        cpt: { state: cptState },
        getFamilyHelper,
      },
      resolvePersonId,
    } = this.props
    // _.values to convert object in to array
    const siblingPersons = _.values(
      getFamilyHelper(cptState).getSiblings(resolvePersonId(false, cptState)),
    )

    return siblingPersons.map((item) => item.person?.id || null)
  }

  /**
   * Selector for indicating twin of proband
   * @param {String} personId - Sibling id in question
   * @returns {React.ReactElement} Twin property selector
   **/
  renderLinkTwins = (personId, probandSex) => (
    <Chooser
      label={getLabel(this.context.localization, PersonFieldPaths.IS_TWIN, 'person')}
      onChange={(e) => {
        this.props.stateManager.setTwinRelationship(
          personId,
          this.props.stateManager.getProband().id,
          e,
        )

        this.props.stateManager.setPersonValue(personId, PersonFieldPaths.PROPERTY_VALUE, e, {
          propertyIdx: {
            [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: PersonProperties.IS_TWIN,
          },
        })
      }}
      selectedValue={this.props.stateManager.getPersonValue(
        personId,
        PersonFieldPaths.PROPERTY_VALUE,
        {
          propertyIdx: {
            [getFieldName(PersonFieldPaths.PROPERTY_TYPE)]: PersonProperties.IS_TWIN,
          },
        },
      )}
      orderSchema={['monozygoticTwin', 'dizygoticTwin', 'N']}
      path={PersonFieldPaths.IS_TWIN}
      disabled={[
        probandSex !== this.props.stateManager.getPersonValue(personId, PersonFieldPaths.SEX),
      ]}
    />
  )
}

export default PersonSiblingsStep
