import React, { useContext } from 'react'
import AuthHook from './hooks/AuthHooks'
import RedirectHooks from './hooks/RedirectHooks'
import Context from './utils/context/Context'
import Spinner from './widgets/Spinner'

const Auth = ({ location }) => {
  const {
    token,
    setAuthenticated,
    isAuthenticated,
    setLoggingIn,
    setToken,
    setFatalErrorMessage,
    getErrorMessage,
    loggingIn,
  } = useContext(Context)

  if (token) {
    AuthHook({
      token,
      setAuthenticated,
      setLoggingIn,
      setToken,
      setFatalErrorMessage,
      getErrorMessage,
    })
  } else {
    RedirectHooks({
      setAuthenticated,
      setLoggingIn,
      setFatalErrorMessage,
      loggingIn,
      location,
      getErrorMessage,
      isAuthenticated,
    })
  }

  return <Spinner spinnerMessage="Loading the application..." />
}

export default Auth
