import { FormGroup } from '@blueprintjs/core'
import React, { useContext } from 'react'
import ButtonGroup from './ButtonGroup'
import Context from '../utils/context/Context'
import { getLabel, getOptions } from '../utils/helpers'

function Chooser({
  label,
  onChange,
  disabled,
  selectedValue,
  orderSchema,
  path,
  patient,
  subLabel = undefined,
}) {
  const { localization } = useContext(Context)

  return (
    <React.Fragment>
      <FormGroup label={label || getLabel(localization, path)} subLabel={subLabel || ''}>
        <ButtonGroup
          disabled={disabled}
          onChange={onChange}
          selectedValue={selectedValue}
          options={getOptions(localization, path, orderSchema, patient)}
        />
      </FormGroup>
    </React.Fragment>
  )
}

export default Chooser
