import { navigate } from '@reach/router'
import React, { useRef, useState, useContext, useEffect, useCallback } from 'react'
import IdleTimer from 'react-idle-timer'

import Context from './utils/context/Context'
import { login, callLogout } from './utils/persistance.js'
import PopUpCard from './widgets/PopUpCard'

const SessionTimeout = ({ children }) => {
  const date = new Date()

  const timeoutConstant = {
    SESSION_TIMEOUT_MIL_SECONDS: 1000 * 60 * 20,
    // Session refreshes in the front end.
    // SESSION_TIMEOUT_MIL_SECONDS + WARNING_DURATION_SECONDS
    LOGOUT_MIL_SECONDS: 1000 * 60 * 22,
    // Login again before the session expired in the backend.
    LOGIN_MIL_SECONDS: 1000 * 60 * 29,
    WARNING_DURATION_SECONDS: 120,
  }

  const { isAuthenticated, setAuthenticated, setLoggingIn, setTimeoutModal, timeoutModal } =
    useContext(Context)
  const [timeoutCountdown, setTimeoutCountdown] = useState(timeoutConstant.WARNING_DURATION_SECONDS)
  const [hasMultiTabs, setHasMultiTabs] = useState(false)
  const idleTimer = useRef(null)
  const countdownInterval = useRef(null)

  const broadcastChannel = useCallback(() => {
    return new BroadcastChannel('re-login-ch')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMultiTabs])

  useEffect(() => {
    if (timeoutCountdown <= 0) {
      handleLogout()
    }

    // eslint-disable-next-line
  }, [timeoutCountdown])

  useEffect(() => {
    // broadcast message for the other tabs in the browser.
    const channel = broadcastChannel()

    channel.postMessage({
      hasMultiTabs: true,
    })

    channel.onmessage = (event) => {
      if (event.data.hasMultiTabs) {
        handleContinue()
      }
    }

    // eslint-disable-next-line
  }, [broadcastChannel])

  const clearSessionInterval = () => {
    if (countdownInterval.current) {
      clearInterval(countdownInterval.current)
    }
  }

  const handleLogout = () => {
    setTimeoutModal(() => ({
      type: '',
      open: false,
    }))

    clearSessionInterval()
    setAuthenticated(false)
    setLoggingIn(true)

    callLogout(
      async () => {
        // redirect to homepage on success
        await navigate('/home')
        setTimeoutModal(() => ({
          type: 'timedOut',
          open: true,
        }))
      },
      (err) => {
        // redirect to home and display error message
        throw new Error(err)
      },
    )
  }

  const handleContinue = () => {
    const { reset } = idleTimer.current

    login(
      () => {
        setTimeoutModal({
          type: '',
          open: false,
        })

        clearSessionInterval()
        setTimeoutCountdown(timeoutConstant.WARNING_DURATION_SECONDS)
        reset()
      },
      (err) => {
        // Should not invoke the handleLogout function
        // because it runs on the other tabs in the multiple tab scenario
        // and then has the current tab logged out immediately as well.
        throw new Error(err)
      },
    )
  }

  const onAction = () => {
    const { getElapsedTime, reset } = idleTimer.current

    // The user should login every 29 minutes
    // before the session is expired in the backend.
    if (getElapsedTime() > timeoutConstant.LOGIN_MIL_SECONDS) {
      login(
        () => {
          // Reset everything including
          // `elapsedTime`, `timeout`, `lastActiveTime`, and etc.
          reset()
        },
        (err) => {
          handleLogout()
          throw new Error(err)
        },
      )
    }
  }

  const onActive = () => {
    const { getLastActiveTime } = idleTimer.current
    const { LOGOUT_MIL_SECONDS } = timeoutConstant

    // Forcefully logout when the idle time is over 22 minutes
    // including `lock`, `sleep`, or `hibernate` time in the machine
    // and the time the user is working on another apps in the machine
    // or tabs at the browser
    if (getLastActiveTime() < date.getTime() - LOGOUT_MIL_SECONDS) {
      handleLogout()
    }
  }

  const onIdle = () => {
    if (isAuthenticated && !timeoutModal.open) {
      setTimeoutModal({
        type: 'warning',
        open: true,
      })
    }

    countdownInterval.current = setInterval(() => {
      setTimeoutCountdown((count) => count - 1)
    }, 1000)
  }

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        timeout={timeoutConstant.SESSION_TIMEOUT_MIL_SECONDS}
        onActive={onActive}
        onAction={onAction}
        onIdle={onIdle}
        // startManually
        // `crossTab` is required only for the `onIdle` function
        crossTab={{
          emitOnAllTabs: true,
          type: 'broadcastChannel',
        }}
      >
        {children}
      </IdleTimer>
      {timeoutModal.open && timeoutModal.type === 'warning' && (
        <PopUpCard
          title="Session expiring"
          intent="warning"
          message="Your session will expire soon due to inactivity."
          buttons={[
            {
              id: 'resumeButton',
              text: 'Resume ' + timeoutCountdown,
              intent: 'none',
              action() {
                // current browser tab
                handleContinue()
                // handling the other browser tabs
                setHasMultiTabs(!hasMultiTabs)
              },
            },
          ]}
        />
      )}
    </>
  )
}

export default SessionTimeout
