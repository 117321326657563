import { Checkbox, FormGroup, TextArea } from '@blueprintjs/core'
import _ from 'lodash'
import React, { useContext } from 'react'
import { PERSON_CONDITIONS } from '../utils/ConditionTypes'
import Context from '../utils/context/Context'
import { getLabel } from '../utils/helpers'
import {
  PersonFieldPaths as paths,
  PersonProperties as properties,
  PersonSurgeries as surgeries,
  getFieldName,
} from '../utils/QuestionnaireStateManager'
import CancerHistory from '../widgets/CancerHistory'
import Chooser from '../widgets/Chooser'
import IntegerInput from '../widgets/IntegerInput'

const probandPropertyMap = {
  [properties.HAS_BEEN_SEEN_BY_TEAM]: properties.HAS_RELATIVE_SEEN_BY_TEAM,
  [properties.HAS_CANCER]: properties.HAS_RELATIVE_HAS_CANCER,
  [surgeries.HYSTERECTOMY]: properties.HAS_RELATIVE_HAVE_HYSTERECTOMY,
  [surgeries.OOPHORECTOMY]: properties.HAS_RELATIVE_REMOVED_OVARIES,
  [paths.GENETIC_TESTING_PERFORMED]: properties.HAS_RELATIVE_SEEN_ELSEWHERE,
}

export default function RelativeLinkingQuestionsStep({
  navId,
  proband,
  propertyPath,
  propertyType,
  personProperty,
  stateManager,
  otherQuestions,
}) {
  const { localization } = useContext(Context)

  let persons = Object.values(stateManager.getFamilyHelper().getAllPersons()).filter(
    (person) =>
      person.relationshipToProband !== 'proband' &&
      person.relationshipToProband !== 'probandsPartner',
  )

  const isFemaleOnlyProperty =
    personProperty === surgeries.HYSTERECTOMY || personProperty === surgeries.OOPHORECTOMY

  if (isFemaleOnlyProperty) {
    persons = persons.filter(({ sex }) => sex === 'F')
  }

  const relativeType = probandPropertyMap[personProperty]

  /**
   * Returns the question title for the page.
   * @returns {String} The formatted question title
   **/
  const getQuestionLabel = () => {
    return getLabel(
      localization,
      propertyPath,
      propertyType === paths.CONDITIONS ? 'person' : 'patient',
    )
  }

  /**
   * Returns a formatted label for family member.
   * @param {Object} defaults A person properties.
   * @param {Object} defaults.name Name properties, `firstName` and `lastName`.
   * @param {String} defaults.relationshipToProband The relationship string value.
   * @returns {String} The name with the relation with the proband.
   **/
  const getPersonLabel = ({
    name: { firstName = '', lastName = '' } = {},
    relationshipToProband,
  }) => {
    const makeCapitalized = (_name) => {
      return _name.trim() ? _name[0].toUpperCase() + _name.substring(1).toLowerCase() : _name
    }

    const _firstName = makeCapitalized(firstName)
    const _lastName = makeCapitalized(lastName)
    const fullName = `${_firstName} ${_lastName}`

    relationshipToProband = relationshipToProband.replace('probands', '')

    let relationship = ''

    const makeSpaceForCamelCase = () => {
      for (let i = 0; i < relationshipToProband.length; i++) {
        const currentChar = relationshipToProband[i]
        const nextChar = relationshipToProband[i + 1]

        relationship += !relationship ? currentChar.toUpperCase() : currentChar

        if (!nextChar) {
          break
        }

        if (nextChar !== nextChar.toLowerCase()) {
          relationship += ' '
        }
      }
    }

    makeSpaceForCamelCase()

    return (
      <span>
        {fullName?.trim() || ''}
        <span className="relationship-label">
          {fullName.trim() ? ` (${relationship})` : relationship}
        </span>
      </span>
    )
  }

  /**
   * Returns stateManager method, `updatePersonValue` with setting the parameters.
   * @param {Object} defaults The parameters to invoke the `updatePersonCancer`.
   * @param {String} defaults.personId The selected person id.
   * @param {Array.<String>} defaults.path Property name and value to be set and to be searched.
   * @param {String} defaults.field The key name for the creation and the update.
   * @param {String | Number | Boolean | Object | Undefined} defaults.newFieldValue
   * The value to be set in `field`.
   * @param {String} defaults.id The id for `conditions`.
   * @param {String} defaults.label The question for `conditions`.
   * @returns {Function} `updatePersonValue`.
   **/
  const setPersonProperty = async ({
    personId,
    path,
    field = undefined,
    targetIndex = undefined,
    newFieldValue = undefined,
  }) => {
    return stateManager.updatePersonValue({
      personId,
      path,
      field,
      targetIndex,
      newFieldValue,
    })
  }

  const checkedItems = persons.reduce((init, add) => {
    const properties = add[propertyType]

    if (!Array.isArray(properties)) {
      const { length } = Object.keys(properties)
      !!length && init.push({ ...properties, id: add.id })
    } else {
      const property = properties.find(({ id, type }) =>
        id ? id === personProperty : type === personProperty,
      )

      !!property && init.push({ ...property, id: add.id })
    }

    return init.flat()
  }, [])

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   * @param {String} `personId`.
   **/
  const handleCheckbox = async (e, personId) => {
    const probandValue = stateManager.getProband().properties
    const person = persons.find(({ id }) => id === personId)

    const probandTargetIndex = probandValue.findIndex(({ type = '' }) => type === relativeType)

    let targetIndex

    if (propertyType !== 'geneticTesting') {
      targetIndex = person[propertyType].findIndex(({ type = '', id = '' }) =>
        propertyType === 'conditions'
          ? id === navId
          : type === (navId === 'genderIdentity' ? personProperty : navId),
      )
    }

    const { label = '', type = '' } = PERSON_CONDITIONS.find(({ id }) => id === navId) || {}

    if (e.target.checked) {
      await Promise.all([
        // Create
        await setPersonProperty({
          personId,
          path: [propertyType],
          targetIndex,
          newFieldValue:
            propertyType === 'surgeries'
              ? { type: navId }
              : propertyType === 'conditions'
              ? { id: navId, type, label, [paths.IS_PRESENT]: 'Y', qualifiers: [] }
              : propertyType === 'geneticTesting'
              ? { performed: 'Y' }
              : {
                  type: navId === 'genderIdentity' ? personProperty : navId,
                  [paths.IS_PRESENT]: 'Y',
                },
        }),
        relativeType &&
        (probandTargetIndex === -1 || probandValue[probandTargetIndex].isPresent === 'N')
          ? await setPersonProperty({
              personId: proband,
              path: [paths.PROPERTIES],
              targetIndex: probandTargetIndex,
              // field: paths.IS_PRESENT,
              newFieldValue: { type: relativeType, [paths.IS_PRESENT]: 'Y' },
            })
          : undefined,
      ])
    } else {
      await Promise.all([
        // Delete
        await setPersonProperty({
          personId,
          path: [propertyType],
          // path: [propertyType, navId === 'genderIdentity' ? personProperty : navId],
          targetIndex,
        }),
        personProperty === 'hasCancer'
          ? await setPersonProperty({
              personId,
              path: ['cancers'],
            })
          : undefined,
        personProperty === 'hasDifferentGenderIdentity' && person.genderIdentity
          ? await setPersonProperty({
              personId,
              path: ['genderIdentity'],
            })
          : undefined,
        // Update
        relativeType && checkedItems.length === 1
          ? await setPersonProperty({
              personId: proband,
              path: [paths.PROPERTIES],
              targetIndex: probandTargetIndex,
              newFieldValue: { type: relativeType, [paths.IS_PRESENT]: 'N' },
            })
          : undefined,
      ])
    }
  }

  /**
   * Selects the related render question.
   * @param {Object} Person relative properties.
   * @returns {ReactDOM|null} JSX DOM.
   **/
  const renderOtherQuestions = (person) => {
    switch (personProperty) {
      case properties.HAS_CANCER:
        return renderCancerSelector(person, stateManager, localization)
      case surgeries.HYSTERECTOMY:
      case surgeries.OOPHORECTOMY:
        return renderSurgeryQuestions(person, personProperty, stateManager)
      case paths.GENETIC_TESTING_PERFORMED:
      case properties.HAS_BEEN_SEEN_BY_TEAM:
        return renderTextAreaQuestions(person, stateManager, localization, personProperty)
      case properties.HAS_DIFFERENT_GENDER_IDENTITY:
        return renderGenderIdentityQuestions(person, stateManager)
      default:
        return null
    }
  }

  return (
    <>
      <div className="mainLabel">
        <p>{getQuestionLabel()}</p>
      </div>
      {persons.length ? (
        <FormGroup label="Please select all who apply:" className="personCheckList">
          {persons.map((person) => {
            const isChecked = !!checkedItems.find(({ id }) => id === person.id)

            return (
              <div key={person.id + '-check-list'}>
                <Checkbox
                  checked={isChecked}
                  label={getPersonLabel(person)}
                  onChange={(e) => handleCheckbox(e, person.id)}
                  large
                />
                {isChecked && otherQuestions && (
                  <div className="otherQuestions">{renderOtherQuestions(person)}</div>
                )}
              </div>
            )
          })}
        </FormGroup>
      ) : (
        <p>
          There are no&nbsp;
          <b>{isFemaleOnlyProperty && 'female '}</b>
          relatives entered. Please add persons in previous steps
        </p>
      )}
    </>
  )
}

/**
 * Renders extra questions specific to surgeries, age removed & reason
 * @returns {ReactDOM}
 **/
const renderCancerSelector = (person, stateManager, localization) => (
  <CancerHistory
    label={getLabel(localization, paths.TYPES_OF_CANCER, 'person')}
    personId={person.id}
    disabled={_.isNil(person)}
    stateManager={stateManager}
  />
)

/**
 * Renders extra questions specific to surgeries, age removed & reason
 * @returns {ReactDOM}
 **/
const renderSurgeryQuestions = (person, personProperty, stateManager) => {
  return (
    <>
      <FormGroup label={'Age removed?'} className="ageRemoved">
        <IntegerInput
          disabled={_.isNil(person)}
          onValueChange={(e) => {
            stateManager.setPersonValue(person.id, paths.SURGERY_AGE, e, {
              propertyIdx: { [getFieldName(paths.SURGERY_TYPE)]: personProperty },
            })
          }}
          value={stateManager.getPersonValue(person.id, paths.SURGERY_AGE, {
            propertyIdx: { [getFieldName(paths.SURGERY_TYPE)]: personProperty },
          })}
          min={0}
          max={150}
        />
      </FormGroup>
      <FormGroup label={'What was the reason?'} className="whatTheReason">
        <TextArea
          rows={1}
          growVertically={true}
          disabled={_.isNil(person)}
          maxLength="255"
          onChange={(e) =>
            stateManager.setPersonValue(person.id, paths.SURGERY_REASON, e.target.value, {
              propertyIdx: { [getFieldName(paths.SURGERY_TYPE)]: personProperty },
            })
          }
          value={stateManager.getPersonValue(person.id, paths.SURGERY_REASON, {
            propertyIdx: { [getFieldName(paths.SURGERY_TYPE)]: personProperty },
          })}
        />
      </FormGroup>
    </>
  )
}

/**
 * Renders extra questions specific to surgeries, age removed & reason
 * @returns {ReactDOM}
 **/
const renderTextAreaQuestions = (person, stateManager, localization, property) => {
  const isSeenByTeam = property === properties.HAS_BEEN_SEEN_BY_TEAM
  const path = isSeenByTeam
    ? paths.HAS_BEEN_SEEN_BY_TEAM_LOCATION
    : paths.GENETIC_TESTING_DESCRIPTION

  return (
    <>
      <FormGroup label={getLabel(localization, path, 'patient')}>
        <TextArea
          rows={1}
          growVertically={true}
          maxLength="255"
          onChange={(e) => {
            const targetIndex = person[paths.PROPERTIES].findIndex(
              ({ type }) => type === properties.HAS_BEEN_SEEN_BY_TEAM,
            )

            const newFieldValue = {
              ...person[paths.PROPERTIES][targetIndex],
              value: e.target.value,
            }

            if (!e.target.value) delete newFieldValue.value

            isSeenByTeam
              ? stateManager.updatePersonValue({
                  personId: person.id,
                  path: [paths.PROPERTIES],
                  targetIndex,
                  newFieldValue,
                })
              : stateManager.setPersonValue(person.id, path, e.target.value)
          }}
          value={
            isSeenByTeam
              ? stateManager.getPersonValue(person.id, paths.PROPERTY_VALUE, {
                  propertyIdx: { [getFieldName(paths.PROPERTY_TYPE)]: property },
                }) || ''
              : stateManager.getPersonValue(person.id, path) || ''
          }
        />
      </FormGroup>
    </>
  )
}

/**
 * Renders extra questions specific to surgeries, age removed & reason
 * @returns {ReactDOM}
 **/
const renderGenderIdentityQuestions = (person, stateManager) => {
  const sexLabel = { M: 'Male', F: 'Female' }
  const sex = stateManager.getPersonValue(person.id, 'sex')

  return (
    <>
      <label className="bp3-label">
        Sex assigned at birth:
        <br />
        <b>{sexLabel[sex]}</b>
      </label>
      <Chooser
        label="Gender Identity"
        onChange={(e) => stateManager.setPersonValue(person.id, paths.GENDER_IDENTITY, e)}
        selectedValue={stateManager.getPersonValue(person.id, paths.GENDER_IDENTITY)}
        orderSchema={sex === 'M' ? ['F', 'nonBinary', 'O'] : ['M', 'nonBinary', 'O']}
        patient={'patient'}
        path={paths.GENDER_IDENTITY}
      />
    </>
  )
}
