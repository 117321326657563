import { Button } from '@blueprintjs/core'
import _ from 'lodash'
import React, { Component } from 'react'

import { getOffspringStr } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager'
import DOBComponent from '../widgets/DOBComponent'
import FullName from '../widgets/FullName'
import LifeStatus from '../widgets/LifeStatus'
import MultiPersonCards from '../widgets/MultiPersonCards'

class ChildInfoStep extends Component {
  constructor(props) {
    super(props)

    this.state = {
      childPersonIds: [],
      childCount: {
        M: 0,
        F: 0,
      },
    }
  }

  componentDidMount() {
    this.updateState()
  }

  componentDidUpdate(prevProps) {
    if (this.props.persons !== prevProps.persons) this.updateState()
  }

  render() {
    return (
      <React.Fragment>
        <div className="mainLabel">
          <p>Please add your biological children (if applicable)</p>
        </div>
        <div className="addChildren">
          {this.renderAddChildButton(this.props.person.id, this.state.childCount.M, 'M')}
          {this.renderAddChildButton(this.props.person.id, this.state.childCount.F, 'F')}
        </div>
        <MultiPersonCards
          personIds={this.state.childPersonIds}
          entryRenderer={this.renderChildEntry.bind(this)}
          personLabel={(personId) => `Patient's ${getOffspringStr(this.getPersonSex(personId))}`}
          stateManager={this.props.stateManager}
          getValue={this.props.stateManager.getPersonValue.bind(this.props.stateManager)}
        />
      </React.Fragment>
    )
  }

  updateState = () => {
    this.setState(() => ({
      childPersonIds: this.getChildPersonIds(this.props.person.id),
      childCount: {
        M: this.getChildCount('M'),
        F: this.getChildCount('F'),
      },
    }))
  }

  renderAddChildButton = (id, count, sex) => {
    const sexLabel = {
      M: 'Son (Boy)',
      F: 'Daughter (Girl)',
    }[sex]

    const buttonHandler = () => {
      this.props.stateManager.setChildCount(id, sex, count + 1, ['proband'])
    }

    return (
      <Button
        icon="add"
        text={'Add ' + sexLabel}
        minimal={true}
        intent="primary"
        className="addButton"
        onClick={buttonHandler}
      />
    )
  }

  getPersonSex(personId) {
    const { SEX } = PersonFieldPaths

    return this.props.stateManager.getPersonValue(personId, SEX)
  }

  renderChildEntry = (personId) => {
    const stateManager = this.props.stateManager

    return (
      <div key={personId}>
        <FullName
          id={personId}
          getValue={stateManager.getPersonValue.bind(stateManager)}
          setValue={stateManager.setPersonValue.bind(stateManager)}
          personIdWithCreate={personId}
          sex={stateManager.getPersonValue(personId, PersonFieldPaths.SEX)}
        />
        <LifeStatus personId={personId} personIdWithCreate={personId} stateManager={stateManager} />
        <DOBComponent
          stateManager={this.props.stateManager}
          dobDateStr={stateManager.getPersonValue(personId, PersonFieldPaths.DATE_OF_BIRTH)}
          onChange={(date) => {
            stateManager.setPersonValue(
              personId,
              PersonFieldPaths.DATE_OF_BIRTH,
              date && date !== 'Invalid Date' ? date.toISOString() : '',
            )
          }}
        />
      </div>
    )
  }

  getChildPersonIds = (id) => {
    const children = ['M', 'F'].map((sex) =>
      this.props.stateManager.getFamilyHelper().getChildren(id, sex),
    )

    return _.flatten(children).map((child) => child.id)
  }

  getChildCount = (sex) => {
    return (
      this.props.stateManager.getFamilyHelper().getChildren(this.props.person.id, sex)?.length || 0
    )
  }
}

export default ChildInfoStep
