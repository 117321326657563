import { navigate } from '@reach/router'
import React, { useEffect, useContext } from 'react'
import Context from './utils/context/Context'
import Spinner from './widgets/Spinner'

const Loading = () => {
  const { setToken } = useContext(Context)

  useEffect(() => {
    if (window.location.search) {
      setToken(window.location.search.split(/\/?[a-zA-Z]n=/)[1])
    }

    navigate('/auth')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Spinner spinnerMessage="Redirecting the page..." />
}

export default Loading
