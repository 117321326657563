import { Router } from '@reach/router'
import React, { useState } from 'react'

import Auth from './Auth'
import Home from './Home'
import Loading from './Loading'
import Message from './Message'
import Questionnaire from './Questionnaire.jsx'
import ThankYou from './ThankYou.jsx'
import Context from './utils/context/Context'
import { getErrorMessage } from './utils/helpers'
import AlertBar from './widgets/AlertBar'
import PopUpCard from './widgets/PopUpCard'
import './App.css'

function App() {
  const [demoMode, setDemoMode] = useState(true)
  const [loading, setLoading] = useState({})
  const [toasts, setToasts] = useState([])
  const [localization, setLocalization] = useState({})
  const [fatalErrorMessage, setFatalErrorMessage] = useState(null)
  const [dialogBox, setDialogBox] = useState({ show: false })
  const [loggingIn, setLoggingIn] = useState(true)
  const [isAuthenticated, setAuthenticated] = useState(false)
  const [timeoutModal, setTimeoutModal] = useState({ type: '', open: false })
  const [thankYou, setThankYou] = useState({})
  const [appState, setAppState] = useState({})
  const [token, setToken] = useState('')

  const addToast = (statusId, intent, message) => {
    const toast = {
      id: statusId,
      intent,
      message,
    }

    setToasts([...toasts, toast])
  }

  // Please do not set useEffect in App.js
  // if login is set in useEffect particularly,
  // it will keep logging in and get a new set-cookie over and over
  // whenever refreshing. (It is not our biz scenario.)

  return (
    // if Context is set, please do not use props for the context elements.
    <Context.Provider
      value={{
        loading,
        setLoading,
        demoMode,
        localization,
        setDemoMode,
        dialogBox,
        setDialogBox,
        setLoggingIn,
        isAuthenticated,
        setAuthenticated,
        getErrorMessage,
        loggingIn,
        fatalErrorMessage,
        addToast,
        timeoutModal,
        setTimeoutModal,
        setAppState,
        appState,
        setThankYou,
        thankYou,
        setLocalization,
        setFatalErrorMessage,
        token,
        setToken,
      }}
    >
      <div className="App">
        <AlertBar
          toasts={toasts}
          removeToast={(i) => {
            const newToasts = [...toasts]
            newToasts.splice(i, 1)
            setToasts(newToasts)
          }}
        />
        <Router>
          <Loading default path="/" />
          <Auth path="/auth" />
          <Home path="/home" />
          <Questionnaire path="/questionnaire" id="questionnaire" />
          <ThankYou path="/thankyou" />
          <Message path="/message" />
        </Router>
        {timeoutModal.open && timeoutModal.type === 'timedOut' && (
          <PopUpCard
            title="Session expired"
            intent="danger"
            message={
              'This session has timed out due to inactivity. ' +
              'To resume, please click or copy-paste the link you received ' +
              'from your healthcare provider again.'
            }
            buttons={[
              {
                id: 'sessionExpiryOkButton',
                text: 'Ok',
                intent: 'none',
                action() {
                  setTimeoutModal({ open: false, type: '' })
                },
              },
            ]}
          />
        )}
      </div>
    </Context.Provider>
  )
}

export default App
