import { navigate } from '@reach/router'
import { useEffect } from 'react'

const RedirectHooks = ({ setLoggingIn, loggingIn }) => {
  useEffect(() => {
    if (loggingIn) {
      setLoggingIn(() => false)
    }

    navigate('/questionnaire', { state: { fromRedirect: true }, replace: true })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return true
}

export default RedirectHooks
