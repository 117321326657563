import { Callout, FormGroup, MenuItem, Button } from '@blueprintjs/core'
import { MultiSelect } from '@blueprintjs/select'
import _ from 'lodash'
import React, { Component } from 'react'
import uuidv4 from 'uuid'
import ancestryOptions from '../utils/AncestryOptions.js'
import Context from '../utils/context/Context'
import { getLabel } from '../utils/helpers.js'

import RelatedByBlood from '../widgets/RelatedByBlood'

import '../../node_modules/@blueprintjs/select/lib/css/blueprint-select.css'

class ParentsStep extends Component {
  static contextType = Context
  constructor(props) {
    super(props)

    this.state = {
      maternalAncestryQueryStr: '',
      paternalAncestryQueryStr: '',
    }

    const { persons, relationships } = this.props.stateManager.getState()

    this.persons = persons
    this.relationships = relationships

    this.bothParentMembers = Object.values(this.persons).filter(
      ({ relationshipToProband }) =>
        relationshipToProband === 'probandsFather' || relationshipToProband === 'probandsMother',
    )
  }

  componentDidMount() {
    if (this.bothParentMembers.length < 2) {
      const [_parent = undefined] = this.bothParentMembers
      const fatherId = _parent?.sex === 'M' ? _parent.id : uuidv4()
      const motherId = _parent?.sex === 'F' ? _parent.id : uuidv4()

      let parents = {}

      const _parents = {
        [fatherId]: {
          id: fatherId,
          relationshipToProband: 'probandsFather',
          sex: 'M',
        },
        [motherId]: {
          id: motherId,
          relationshipToProband: 'probandsMother',
          sex: 'F',
        },
      }

      if (_parent) {
        parents = { [_parent.id]: { ..._parent } }

        if (_parent.sex === 'M') {
          Object.assign(parents, { [motherId]: _parents[motherId] })
        } else {
          Object.assign(parents, { [fatherId]: _parents[fatherId] })
        }
      } else {
        Object.assign(parents, _parents)
      }

      const relationships = Object.values(parents).map((parent) => ({
        id:
          _parent && parent.id === _parent.id
            ? this.relationships.filter(
                ({ type, source }) => type === 'parent' && source === _parent.id,
              )[0].id
            : uuidv4(),
        properties: [],
        source: parent.id,
        target: this.props.person.id,
        type: 'parent',
      }))

      this.props.stateManager.setState(() => ({
        ...this.props.stateManager.state,
        persons: { ...this.persons, ...parents },
        relationships: [
          ...relationships,
          {
            id: uuidv4(),
            properties: [],
            source: fatherId,
            target: motherId,
            type: 'partnership',
          },
        ],
      }))
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="mainLabel">
          <p>
            Please add one or more known countries or regions of ancestry on both sides of the
            family:
          </p>
        </div>
        {this.renderAncestryMultiSelect('maternal')}
        {this.renderAncestryMultiSelect('paternal')}
        <Callout intent="primary">
          <p className="text-size-sm m-0">
            Some medical conditions can be passed down in families (inherited). These inherited
            conditions can be more common in people from certain parts of the world. For this
            reason, it is important for us to know what part of the world your family is from. This
            information may be used in our risk assessment, as well as in planning and interpreting
            genetic test results. Ancestry is different from nationality or citizenship, and many
            people have ancestors from more than one area.
          </p>
        </Callout>
        {this.renderAncestryQuestion(
          'consanguineous',
          getLabel(this.context.localization, 'patient.parents.consanguinity'),
          getLabel(this.context.localization, 'patient.parents.consanguinity.related'),
        )}
        {this.renderAncestryQuestion(
          'ashkenaziJewish',
          getLabel(this.context.localization, 'patient.ancestry.ashkenaziJewish'),
        )}
      </React.Fragment>
    )
  }

  renderAncestryQuestion(ancestryProperty, label, subLabel = undefined) {
    const { person, stateManager } = this.props

    return (
      <RelatedByBlood
        ancestryProperty={ancestryProperty}
        personId={person.id}
        stateManager={stateManager}
        label={label}
        subLabel={subLabel}
        bothParentMembers={this.bothParentMembers}
      />
    )
  }

  renderAncestryMultiSelect = (side) => {
    const selectedAncestryValues = this.getAncestryBySide(side) || []
    const ancestryOptions = this.getAncestryOptions()
    const clearButton =
      selectedAncestryValues.length > 0 ? (
        <Button icon="cross" minimal={true} onClick={_.partial(this.handleClearAncestry, side)} />
      ) : null

    return (
      <FormGroup label={getLabel(this.context.localization, `ancestry.${side}`, 'patient')}>
        <MultiSelect
          items={ancestryOptions}
          selectedItems={_.map(selectedAncestryValues, this.getOptionObj)}
          itemRenderer={_.partial(this.renderAncestryItem, side)}
          tagRenderer={(optionObj) => optionObj.value}
          large={true}
          fill={true}
          tagInputProps={{
            onRemove: _.partial(this.handleAncestryItemDeselect, side),
            tagProps: {
              minimal: true,
            },
            rightElement: clearButton,
          }}
          popoverProps={{
            minimal: true,
          }}
          onItemSelect={_.partial(this.handleAncestryItemSelect, side)}
          itemPredicate={_.partial(this.itemPredicate, side)}
          onQueryChange={(queryStr) => {
            const newState = {}
            newState[this.getQueryStrStateKeyBySide(side)] = queryStr
            this.setState(newState)
          }}
          query={this.state[this.getQueryStrStateKeyBySide(side)]}
        />
      </FormGroup>
    )
  }

  getAncestryOptions = () => {
    const options = _.map(ancestryOptions, this.getOptionObj)
    options.push({ type: 'custom' })

    return options
  }

  getOptionObj = (val) => {
    return { value: val, type: 'preset' }
  }

  itemPredicate = (side, query, optionObj) => {
    if (query && optionObj.type === 'custom') {
      // we want the custom value item to always be rendered
      return true
    } else {
      const selectedItems = this.getAncestryBySide(side) || []

      // do a partial string match
      return (
        (!query || optionObj.value.toLowerCase().indexOf(query.toLowerCase()) !== -1) &&
        !_.includes(selectedItems, optionObj.value)
      )
    }
  }

  renderAncestryItem = (side, optionObj, { handleClick, modifiers }) => {
    if (optionObj.type === 'preset') {
      return (
        <MenuItem
          active={modifiers.active}
          key={optionObj.value}
          onClick={handleClick}
          text={optionObj.value}
          shouldDismissPopover={false}
        />
      )
    } else {
      const queryStr = this.state[this.getQueryStrStateKeyBySide(side)]

      return (
        <MenuItem
          active={modifiers.active}
          key={'custom'}
          onClick={handleClick}
          text={'Add "' + queryStr + '"'}
          icon="add"
          shouldDismissPopover={false}
        />
      )
    }
  }

  handleAncestryItemSelect = (side, optionObj) => {
    let ancestryVal

    if (optionObj.type === 'custom') {
      ancestryVal = this.state[this.getQueryStrStateKeyBySide(side)]
    } else {
      ancestryVal = optionObj.value
    }

    const ancestryPath = this.props.stateManager.getPersonAncestryPath(side)
    this.props.stateManager.addToPersonArray(this.props.person.id, ancestryPath, ancestryVal)

    // Clear the input
    const newState = {}
    newState[this.getQueryStrStateKeyBySide(side)] = ''
    this.setState(newState)
  }

  handleAncestryItemDeselect = (side, val) => {
    const ancestryPath = this.props.stateManager.getPersonAncestryPath(side)
    this.props.stateManager.removeFromPersonArray(this.props.person.id, ancestryPath, val)
  }

  handleClearAncestry = (side) => {
    const ancestryPath = this.props.stateManager.getPersonAncestryPath(side)
    this.props.stateManager.removeFromPersonArray(this.props.person.id, ancestryPath, null)
  }

  getAncestryBySide = (side) => {
    const ancestryPath = this.props.stateManager.getPersonAncestryPath(side)

    return this.props.stateManager.getPersonValue(this.props.person.id, ancestryPath)
  }

  getQueryStrStateKeyBySide = (side) => {
    return side + 'AncestryQueryStr'
  }
}

export default ParentsStep
